import React from 'react';
import presets from '../../styles/presets';
import { ContentfulContentBlock } from '../../graphql-types';
import HeadingSection from '../HeadingSection/HeadingSection';
import Container from '../Homepage/Container';
import bgImage from '../../assets/images/careers-header.png';
import { css } from '@emotion/core';

// TODO: Add image for mobile

const Intro = (props: ContentfulContentBlock) => {
  return (
    <section
      css={css`
        margin-top: 20px;
        ${presets.md} {
          padding-left: 25px;
        }
      `}
    >
      <div
        css={css`
          background-image: none;
          background-size: cover;
          background-repeat: no-repeat;
          height: 50vw;
          max-height: 718px;
          max-width: 1454px;
          margin: 0 auto 100px;

          @media (min-width: 500px) {
            margin: 0 auto;
          }

          ${presets.md} {
            background-image: url(${bgImage});
          }
        `}
      >
        <Container
          css={css`
            padding-top: 11vw;
            @media (min-width: 1454px) {
              padding-top: 161px;
            }
          `}
        >
          <HeadingSection {...props} />
        </Container>
      </div>
    </section>
  );
};

export default Intro;
