import React from 'react';
import { css } from '@emotion/core';
import presets from '../../styles/presets';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { File } from '../../graphql-types';

const ImageBelowPerks = () => {
  const { careersTeam } = useStaticQuery<{
    careersTeam: File;
  }>(graphql`
    {
      careersTeam: file(relativePath: { eq: "careers-team.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div
      css={css`
        overflow: hidden;
        max-width: 1560px;
        padding: 0;
        margin-bottom: 55px;

        ${presets.sm} {
          padding: 0 4vw;
          margin-bottom: 80px;
        }

        ${presets.md} {
          margin-bottom: 206px;
        }

        @media (min-width: 1560px) {
          margin: 0 auto 200px;
        }
      `}
    >
      <Image
        fluid={[careersTeam.childImageSharp.fluid]}
        css={{
          display: 'block',
          visibility: 'visible',
          maxWidth: '100%',
          height: 'auto',
          userSelect: 'none',
        }}
      />
    </div>
  );
};

export default ImageBelowPerks;
