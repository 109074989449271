import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components';
import {
  ContentfulCareersPage,
  ContentfulHeadingTextImage,
  ContentfulPressColorfulBox,
} from '../graphql-types';
import { withLayout } from '../components/Layout';
import {
  About,
  Intro,
  Introduce,
  Positions,
  Team,
  Perks,
  ImageBelowPerks,
} from '../components/Careers';

type AboutBox = { aboutBoxes: ContentfulHeadingTextImage[] };
type PositionsBox = { positionsBoxes: ContentfulPressColorfulBox[] };

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    careers: ContentfulCareersPage;
    aboutBoxes: { nodes: [AboutBox] };
    positionsBoxes: { nodes: [PositionsBox] };
  };
}

type Props = OwnProps;

const Careers: React.FC<Props> = ({
  pathContext: { locale },
  data: { careers, aboutBoxes, positionsBoxes },
}) => {
  return (
    <>
      <SEO lang={locale} title={careers.seoTitle} />
      <main
        css={{ backgroundColor: '#fffdfc', marginTop: -30, paddingTop: 30 }}
      >
        <Intro {...careers.introSection} />
        <About
          heading={careers.aboutSection}
          boxes={aboutBoxes.nodes[0].aboutBoxes}
        />
        <Team {...careers.teamSection} />
        <Positions
          heading={careers.positionsSection}
          boxes={positionsBoxes.nodes[0].positionsBoxes}
        />
        <Introduce {...careers.introduceSection} />
        <Perks {...careers.perksSection} />
        <ImageBelowPerks />
      </main>
    </>
  );
};

export const careersPageQuery = graphql`
  query careersPageQuery($locale: String!) {
    careers: contentfulCareersPage(node_locale: { eq: $locale }) {
      seoTitle
      introSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      aboutSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      teamSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        itemsLongText {
          prefix
          heading
          text {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            fluid: fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      positionsSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      introduceSection {
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      perksSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          heading
          shortText
          image {
            fluid: fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    aboutBoxes: allContentfulCareersPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        aboutBoxes {
          heading
          text {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            fluid: fluid(maxWidth: 638) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    positionsBoxes: allContentfulCareersPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        positionsBoxes {
          id
          heading
          shortText
          linkHref
          linkText
        }
      }
    }
  }
`;

export default withLayout(Careers, { isNewLayout: true });
