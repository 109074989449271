import React from 'react';
import presets from '../../styles/presets';
import { ContentfulContentBlock } from '../../graphql-types';
import HeadingSection from '../HeadingSection/HeadingSection';
import Container from '../Homepage/Container';
import bgImage from '../../assets/images/careers-background3.svg';
import { css } from '@emotion/core';

const Introduce = (props: ContentfulContentBlock) => {
  return (
    <section
      css={css`
        margin-top: -80vw;

        ${presets.sm} {
          margin-top: -400px;
        }

        ${presets.lg} {
          margin-top: -500px;
        }

        @media (min-width: 1930px) {
          margin-top: -200px;
        }
      `}
    >
      <div
        css={css`
          background-image: url(${bgImage});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          min-height: 400px;

          ${presets.lg} {
            min-height: 600px;
          }

          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Container css={css``}>
          <HeadingSection {...props} />
        </Container>
      </div>
    </section>
  );
};

export default Introduce;
